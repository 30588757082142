import { Box } from '@chakra-ui/react';
import type { MouseEventHandler } from 'react';
import { forwardRef } from 'react';

import { MenuDrawerHoverIcon } from './MenuDrawerHoverIcon';

interface Props {
  as: 'span' | 'button';
  isActive: boolean;
  onClick: MouseEventHandler<any>;
  text: string;
}

// eslint-disable-next-line react-memo/require-memo
export const LinkInner = forwardRef<any, Props>(({ as, isActive, onClick, text }, ref) => {
  return (
    <Box
      _hover={{ color: 'brand.blueHover', cursor: 'pointer' }}
      as={as}
      display="flex"
      onClick={onClick}
      ref={ref}
      role="group"
      transition="color .2s ease"
    >
      {isActive ? null : <MenuDrawerHoverIcon />}
      {text}
    </Box>
  );
});
