import { Box } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

interface Props {
  children: ReactNode;
  isActive: boolean;
}

export const LinkOuter: FC<Props> = memo(({ children, isActive }) => {
  return (
    <Box
      bg={isActive ? 'brand.beige' : undefined}
      border={isActive ? Border : undefined}
      borderRightRadius="700"
      data-testid="menuDrawerLink"
      fontSize={{ base: '500', md: '700' }}
      fontWeight={isActive ? '600' : undefined}
      lineHeight={{ base: '600', md: '750' }}
      pl={{ base: '300', md: '600' }}
      pos="relative"
      pr="200"
      py="100"
    >
      {children}
    </Box>
  );
});
