import { Box } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { ArrowRight } from '@ours/web-icons';
import type { FC } from 'react';
import { memo } from 'react';

interface Props {}

export const MenuDrawerHoverIcon: FC<Props> = memo(() => {
  return (
    <Box
      _groupHover={{ opacity: '1' }}
      alignItems="center"
      bg="brand.beige"
      border={Border}
      borderRightRadius="700"
      bottom="0"
      display={{ base: 'none', md: 'flex' }}
      justifyContent="center"
      left="0"
      opacity="0"
      pl="50"
      pos="absolute"
      pr="50"
      sx={{
        path: { fill: 'black' },
        svg: { h: '20px' },
      }}
      top="0"
      transition="opacity .2s ease"
      w="500"
    >
      <ArrowRight size="lg" />
    </Box>
  );
});
