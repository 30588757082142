import { _useStore } from '../../store/_useStore';
import type { StoreState } from '../../store/types';

const select = (s: StoreState) => {
  return s.cart.state.context.sessionIdsInCart;
};

export const useSessionIdsInCart = () => {
  return _useStore(select);
};
