import { useEvent } from '../../../hooks/useEvent';
import { _useStore } from '../../store/_useStore';
import type { CartContext } from '../types';

export const useCartActions = () => {
  const send = _useStore((s) => s.cart.send);

  const onCheckout = useEvent(() => {
    send({ type: 'ON_CHECKOUT' });
  });

  const onInit = useEvent((mode: CartContext['mode'], purchaseLocation: string) => {
    send({ mode, purchaseLocation, type: 'INIT' });
  });

  const onAddCompletedCheckoutListener = useEvent((fn: () => void) => {
    send({ fn, type: 'ON_ADD_COMPLETED_CHECKOUT_LISTENER' });
  });

  const onClearCart = useEvent(() => {
    send({ type: 'ON_CLEAR_CART' });
  });

  const onToggleSessionToCart = useEvent((sessionId: string) => {
    send({ sessionId, type: 'ON_TOGGLE_SESSION_TO_CART' });
  });

  const onToggleBillingDrawer = useEvent(() => {
    send({ type: 'ON_TOGGLE_BILLING_DRAWER' });
  });

  const onOpenBillingDrawer = useEvent(() => {
    send({ type: 'ON_OPEN_BILLING_DRAWER' });
  });

  const onOpenStripeBillingCenter = useEvent(() => {
    send({ type: 'ON_OPEN_STRIPE_BILLING_CENTER' });
  });

  const onCloseBillingDrawerFromCheckoutComplete = useEvent(() => {
    send({ type: 'ON_CLOSE_BILLING_DRAWER' });
  });

  const onChangeBillingMethod = useEvent((selectedBillingMethod: string) => {
    send({ selectedBillingMethod, type: 'ON_CHANGE_BILLING_METHOD' });
  });

  return {
    onAddCompletedCheckoutListener,
    onChangeBillingMethod,
    onCheckout,
    onClearCart,
    onCloseBillingDrawerFromCheckoutComplete,
    onInit,
    onOpenBillingDrawer,
    onOpenStripeBillingCenter,
    onToggleBillingDrawer,
    onToggleSessionToCart,
  };
};
