import { CoupleRoutes } from '@ours/utils';

export const MENU_DRAWER = {
  FEELINGS_TEXT: 'Feelings look good on you.',
  FEELINGS_TEXT_2: 'Follow us for more feel good stuff.',
  LINKS: [
    { link: CoupleRoutes.Home, text: 'Your Dashboard' },
    { link: CoupleRoutes.Profile, text: 'Your Info' },
    { link: CoupleRoutes.Roadmap, text: 'Your Roadmap' },
    { link: CoupleRoutes.Store, text: 'Session Store' },
    { action: 'billing', text: 'Billing' },
    { action: 'support', text: 'Support' },
    { action: 'loggout', text: 'Log Out' },
  ],
} as const;
