/* eslint-disable max-lines-per-function */
import { Box } from '@chakra-ui/react';
import { Border } from '@ours/utils';
import { memo } from 'react';
import type { FC, MouseEventHandler, MutableRefObject } from 'react';

export interface IconBtnProps {
  Icon: FC;
  ariaLabel: string;
  btnRef?: MutableRefObject<null | HTMLButtonElement>;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
  type: 'submit' | 'button';
}

export const IconButton: FC<IconBtnProps> = memo(
  ({ Icon, ariaLabel, btnRef, isDisabled, isLoading, onClick, testId, type }) => {
    return (
      <Box
        aria-label={ariaLabel}
        as="button"
        cursor={isLoading ? 'not-allowed' : undefined}
        data-testid={testId}
        disabled={isDisabled || isLoading}
        onClick={onClick}
        pos="relative"
        ref={btnRef}
        sx={{
          _active: {
            '.inner-btn': { bottom: '-3px', right: '-3px' },
          },
        }}
        type={type}
      >
        <Box
          _disabled={{ cursor: 'not-allowed', opacity: '80%', pointerEvents: 'none' }}
          _hover={{ bg: 'brand.blueHover' }}
          bg="brand.beige"
          border={Border}
          borderRadius="500"
          bottom="0"
          className="inner-btn"
          pos="relative"
          px={{ base: '8.5px', md: '10px' }}
          py={{ base: '4px', md: '4px' }}
          right="0"
          sx={{
            ':hover path': {
              fill: 'white',
            },
          }}
          transition="background .25s ease, right .25s ease, bottom .25s ease"
          userSelect="none"
          zIndex={2}
        >
          <Box as="span" visibility={isLoading ? 'hidden' : undefined}>
            <Icon />
          </Box>
        </Box>
        <Box
          bg="#532420"
          borderRadius="500"
          bottom="-3px"
          h="full"
          pos="absolute"
          right="-3px"
          w="full"
        />
      </Box>
    );
  }
);
