import { keyframes } from '@chakra-ui/react';
import { Colors } from '@ours/utils';

const rainbow1 = keyframes`
25% {
  fill: ${Colors.brand.orange};
}

50% {
  fill: ${Colors.brand.pink};
}

75% {
  fill: ${Colors.brand.yellow};
}

100% {
  fill: ${Colors.brand.blue};
}
`;
const rainbow2 = keyframes`
25% {
  fill: ${Colors.brand.pink};
}

50% {
  fill: ${Colors.brand.yellow};
}

75% {
  fill: ${Colors.brand.blue};
}

100% {
  fill: ${Colors.brand.orange};
}
`;
const rainbow3 = keyframes`
25% {
  fill: ${Colors.brand.yellow};
}

50% {
  fill: ${Colors.brand.blue};
}

75% {
  fill: ${Colors.brand.orange};
}

100% {
  fill: ${Colors.brand.pink};
}
`;
const rainbow4 = keyframes`
25% {
  fill: ${Colors.brand.blue};
}

50% {
  fill: ${Colors.brand.orange};
}

75% {
  fill: ${Colors.brand.pink};
}

100% {
  fill: ${Colors.brand.yellow};
}
`;
export const rainbowKeyframes = [rainbow1, rainbow2, rainbow3, rainbow4];
