import type { FC } from 'react';
import { memo } from 'react';

export interface CartIconProps {}

export const CartIcon: FC<CartIconProps> = memo(() => {
  return (
    <svg fill="none" height="37" viewBox="0 0 29 31" width="35" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M2 6.62857H8.28571V2.9C8.28571 1.30098 9.58669 0 11.1857 0H17.8143C19.4133 0 20.7143 1.30098 20.7143 2.9V6.62857H27C28.1046 6.62857 29 7.524 29 8.62857V27.7571C29 29.3562 27.699 30.6571 26.1 30.6571H2.9C1.30098 30.6571 0 29.3562 0 27.7571V8.62857C0 7.524 0.895431 6.62857 2 6.62857ZM18.2286 2.48571H10.7714V6.62857H18.2286V2.48571ZM26.5142 9.11429H2.48571V28.1714H26.5176L26.5142 9.11429Z"
        fill="black"
        fillRule="evenodd"
      />
    </svg>
  );
});
