import { Box, Flex } from '@chakra-ui/react';
import { CoupleRoutes } from '@ours/utils';
import { BrandLogoColored } from '@ours/web-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC, MutableRefObject } from 'react';
import { memo } from 'react';

import { useEvent } from '../../hooks/useEvent';
import { useCartActions } from '../../state/cartMachine/externalActions/useCartActions';
import { useSessionIdsInCart } from '../../state/cartMachine/selectors/useSessionIdsInCart';
import { IconButton } from '../Buttons/IconButton';

import { CartIcon } from './lib/CartIcon';
import { Hamburger } from './lib/Hamburger';

interface Props {
  navButtonRef: MutableRefObject<null | HTMLButtonElement>;
  onOpen: () => void;
}

export const NavBar: FC<Props> = memo(({ navButtonRef, onOpen }) => {
  const selectItems = useSessionIdsInCart();
  const route = useRouter();
  const { onToggleBillingDrawer } = useCartActions();
  const onCartClick = useEvent(() => {
    onToggleBillingDrawer();
  });

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Box>
        <Link href={CoupleRoutes.Home}>
          <BrandLogoColored />
        </Link>
      </Box>

      <Flex alignItems="center" gap="200" py="200">
        {route.pathname === CoupleRoutes.Store ? (
          <Box as="button" onClick={onCartClick} pb="1px" pos="relative" type="button">
            {selectItems.length > 0 ? (
              <Box pos="absolute" right="-3px" top="-3px">
                <Box
                  alignItems="center"
                  bg="#0865FF"
                  borderRadius="full"
                  color="white"
                  display="flex"
                  h="20px"
                  justifyContent="center"
                  textStyle="2xs"
                  w="20px"
                >
                  {selectItems.length}
                </Box>
              </Box>
            ) : null}
            <CartIcon />
          </Box>
        ) : null}

        <Box>
          <IconButton
            Icon={Hamburger}
            ariaLabel="Menu open"
            btnRef={navButtonRef}
            onClick={onOpen}
            type="button"
          />
        </Box>
      </Flex>
    </Flex>
  );
});
