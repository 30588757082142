import { Box } from '@chakra-ui/react';
import { Colors } from '@ours/utils';
import type { FC } from 'react';
import React, { memo } from 'react';

import { rainbowKeyframes } from './rainbowKeyframes';

export interface IBrandLogoColorProps {
  hoverEnabled?: boolean;
  logoVariant?: 'colored' | 'white';
}

export const BrandLogoColored: FC<IBrandLogoColorProps> = memo(
  ({ hoverEnabled = true, logoVariant = 'colored' }) => {
    return (
      <Box
        aria-label="OURS"
        as="svg"
        fill="none"
        height="30"
        role="img"
        sx={
          logoVariant === 'colored' && hoverEnabled
            ? {
                _hover: {
                  'path:nth-of-type(1)': {
                    animation: `${rainbowKeyframes[0]} 0.5s steps(1, jump-start) infinite`,
                  },
                  'path:nth-of-type(2)': {
                    animation: `${rainbowKeyframes[1]} 0.5s steps(1, jump-start) infinite`,
                  },
                  'path:nth-of-type(3)': {
                    animation: `${rainbowKeyframes[2]} 0.5s steps(1, jump-start) infinite`,
                  },
                  'path:nth-of-type(4)': {
                    animation: `${rainbowKeyframes[3]} 0.5s steps(1, jump-start) infinite`,
                  },
                },
              }
            : {}
        }
        viewBox="0 0 117 30"
        width="117"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 15.0004C0 12.0338 0.882697 9.13381 2.53647 6.66717C4.19024 4.20053 6.5408 2.27802 9.29092 1.14275C12.041 0.00747622 15.0672 -0.289563 17.9867 0.289193C20.9062 0.867949 23.5879 2.29651 25.6928 4.39421C27.7976 6.49192 29.2311 9.16456 29.8118 12.0742C30.3925 14.9838 30.0945 17.9996 28.9553 20.7404C27.8162 23.4812 25.8871 25.8238 23.4121 27.472C20.9371 29.1201 18.0272 29.9998 15.0505 29.9998C11.0589 29.9998 7.2307 28.4195 4.40819 25.6066C1.58567 22.7937 0 18.9785 0 15.0004Z"
          fill={logoVariant === 'white' ? 'white' : Colors.brand.yellow}
        />
        <path
          d="M75.0564 0C71.3401 -2.20751e-08 67.7761 1.47116 65.1481 4.08989C62.5202 6.70862 61.0436 10.2604 61.0432 13.9641V29.1053H69.6999V13.9641H89.0711C89.0707 10.2604 87.5941 6.70862 84.9662 4.08989C82.3382 1.47116 78.7742 -2.20751e-08 75.0579 0"
          fill={logoVariant === 'white' ? 'white' : Colors.brand.pink}
        />
        <path
          d="M49.5152 0.83252V16.9557H40.6535V0.83252H32.0029V16.9557C32.0029 20.415 33.3818 23.7325 35.8362 26.1786C38.2906 28.6246 41.6194 29.9988 45.0905 29.9988C48.5615 29.9988 51.8903 28.6246 54.3447 26.1786C56.7991 23.7325 58.178 20.415 58.178 16.9557V0.83252H49.5152Z"
          fill={logoVariant === 'white' ? 'white' : Colors.brand.blue}
        />
        <path
          d="M108.441 12.1785H98.2512C97.4993 12.1613 96.7839 11.8514 96.2582 11.3153C95.7325 10.7791 95.4382 10.0593 95.4382 9.30962C95.4382 8.56 95.7325 7.84013 96.2582 7.30399C96.7839 6.76784 97.4993 6.458 98.2512 6.44072H113.993V0.83252H98.2512C95.9959 0.83252 93.833 1.7254 92.2382 3.31474C90.6435 4.90407 89.7476 7.05968 89.7476 9.30734C89.7476 11.555 90.6435 13.7106 92.2382 15.2999C93.833 16.8893 95.9959 17.7822 98.2512 17.7822H103.23V23.5215H91.0939V29.1266H108.441C110.676 29.0968 112.81 28.1908 114.38 26.6049C115.95 25.0189 116.831 22.8806 116.831 20.6526C116.831 18.4246 115.95 16.2863 114.38 14.7003C112.81 13.1144 110.676 12.2084 108.441 12.1785Z"
          fill={logoVariant === 'white' ? 'white' : Colors.brand.red}
        />
      </Box>
    );
  }
);
