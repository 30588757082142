import { COMPANY_MAIL_TO_LINK, CoupleRoutes, LINKS } from '@ours/utils';
import { gql } from 'graphql-tag';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type { FC, MouseEventHandler } from 'react';
import { memo, useEffect } from 'react';

import { useMenuPaymentLinkQuery } from '../../generated/custom-hooks';
import { useEvent } from '../../hooks/useEvent';
import { useAppActions } from '../../state/appMachine/externalActions/useAppActions';

import { LinkInner } from './LinkInner';
import { LinkOuter } from './LinkOuter';
import type { MENU_DRAWER } from './menuContent';

interface Props {
  isActive: boolean;
  link: (typeof MENU_DRAWER.LINKS)[number];
  onClose: () => void;
}
gql`
  query MenuPaymentLink {
    getUserStripeBillingPortalLink
  }
`;
export const MenuDrawerLink: FC<Props> = memo(({ isActive, link, onClose }) => {
  const { onLogout } = useAppActions();
  const { data } = useMenuPaymentLinkQuery({ context: { batch: true } });
  const router = useRouter();
  const onClick: MouseEventHandler<any> = useEvent((e) => {
    if (e.metaKey) {
      return;
    }

    onClose();
  });
  useEffect(() => {
    router.prefetch(CoupleRoutes.LogOut);
  }, [router]);

  const onAction = useEvent(async () => {
    if (!('action' in link)) {
      throw 'missing action for link';
    }

    if (link.action === 'billing') {
      window.open(data?.getUserStripeBillingPortalLink || LINKS.STRIPE_BILLING_LINK);
    } else if (link.action === 'loggout') {
      onLogout(onClose);
    } else if (link.action === 'support') {
      window.open(COMPANY_MAIL_TO_LINK);
    }
  });

  if ('action' in link) {
    return (
      <LinkOuter isActive={isActive}>
        <LinkInner as="button" isActive={isActive} onClick={onAction} text={link.text} />
      </LinkOuter>
    );
  }

  return (
    <LinkOuter isActive={isActive}>
      <Link href={link.link}>
        <LinkInner as="span" isActive={isActive} onClick={onClick} text={link.text} />
      </Link>
    </LinkOuter>
  );
});
