import { memo } from 'react';

import type { IconBaseProps } from '../IconType';
import { sizeVariants } from '../IconType';

export const ArrowRight = memo((props: IconBaseProps) => {
  const computedSize = sizeVariants[props.size || 'base'];
  const computedColor = props.color || 'black';
  return (
    <svg
      fill="currentColor"
      height={computedSize}
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 17 24"
      width={computedSize}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.20245 12.09L2.54913 16.7433C1.82478 17.468 1.41787 18.4506 1.41787 19.4752C1.41787 20.4998 1.82478 21.4825 2.54913 22.2071C3.27377 22.9315 4.25643 23.3384 5.28102 23.3384C6.30561 23.3384 7.28825 22.9315 8.0129 22.2071L12.6641 17.5538L15.7507 14.4673C16.0628 14.1551 16.3105 13.7845 16.4794 13.3766C16.6484 12.9687 16.7354 12.5315 16.7354 12.09C16.7354 11.6485 16.6484 11.2114 16.4794 10.8035C16.3105 10.3956 16.0628 10.025 15.7507 9.71281L12.6641 6.62458L6.19514 0.156025L0.731787 5.61938L7.20245 12.09Z"
        fill={computedColor}
      />
    </svg>
  );
});
