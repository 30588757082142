import type { FC } from 'react';
import { memo } from 'react';

export interface HamburgerProps {}

export const Hamburger: FC<HamburgerProps> = memo(() => {
  return (
    <svg fill="none" height="26" viewBox="0 0 26 26" width="26" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.21191 6.39344C2.21191 5.61449 2.84338 4.98303 3.62232 4.98303H24.0792V7.80385H3.62232C2.84338 7.80385 2.21191 7.17239 2.21191 6.39344Z"
        fill="black"
      />
      <path
        d="M2.21191 12.637C2.21191 11.858 2.84338 11.2266 3.62232 11.2266H24.0792V14.0474H3.62232C2.84338 14.0474 2.21191 13.4159 2.21191 12.637Z"
        fill="black"
      />
      <path
        d="M2.21191 18.9838C2.21191 18.2048 2.84338 17.5734 3.62232 17.5734H24.0792V20.3942H3.62232C2.84338 20.3942 2.21191 19.7627 2.21191 18.9838Z"
        fill="black"
      />
    </svg>
  );
});
