import type { FC } from 'react';
import { memo } from 'react';

export const Instagram: FC = memo(() => {
  return (
    <svg fill="none" height="35" viewBox="0 0 35 35" width="35" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.6983 3H7.30167C6.16177 3.00319 5.06948 3.45742 4.26345 4.26345C3.45742 5.06948 3.00319 6.16177 3 7.30167L3 27.6983C3.00319 28.8382 3.45742 29.9305 4.26345 30.7366C5.06948 31.5426 6.16177 31.9968 7.30167 32H27.6983C28.8382 31.9968 29.9305 31.5426 30.7366 30.7366C31.5426 29.9305 31.9968 28.8382 32 27.6983V7.30167C31.9968 6.16177 31.5426 5.06948 30.7366 4.26345C29.9305 3.45742 28.8382 3.00319 27.6983 3V3ZM29.5833 27.6983C29.5833 28.1983 29.3847 28.6777 29.0312 29.0312C28.6777 29.3847 28.1983 29.5833 27.6983 29.5833H7.30167C6.80173 29.5833 6.32228 29.3847 5.96877 29.0312C5.61526 28.6777 5.41667 28.1983 5.41667 27.6983V7.30167C5.41667 6.80173 5.61526 6.32228 5.96877 5.96877C6.32228 5.61526 6.80173 5.41667 7.30167 5.41667H27.6983C28.1983 5.41667 28.6777 5.61526 29.0312 5.96877C29.3847 6.32228 29.5833 6.80173 29.5833 7.30167V27.6983Z"
        fill="black"
      />
      <path
        d="M17.4985 10.2539C16.0646 10.2539 14.6629 10.6791 13.4707 11.4758C12.2784 12.2724 11.3491 13.4047 10.8004 14.7295C10.2517 16.0542 10.1081 17.5119 10.3878 18.9183C10.6676 20.3247 11.3581 21.6165 12.372 22.6304C13.3859 23.6444 14.6778 24.3349 16.0841 24.6146C17.4905 24.8943 18.9482 24.7508 20.273 24.202C21.5978 23.6533 22.7301 22.724 23.5267 21.5318C24.3233 20.3395 24.7485 18.9378 24.7485 17.5039C24.7485 15.5811 23.9847 13.737 22.6251 12.3774C21.2654 11.0177 19.4214 10.2539 17.4985 10.2539ZM17.4985 22.3372C16.5426 22.3372 15.6081 22.0538 14.8133 21.5227C14.0184 20.9916 13.3989 20.2367 13.0331 19.3535C12.6673 18.4704 12.5716 17.4985 12.7581 16.561C12.9446 15.6234 13.4049 14.7622 14.0809 14.0862C14.7568 13.4103 15.618 12.9499 16.5556 12.7634C17.4932 12.5769 18.465 12.6727 19.3482 13.0385C20.2314 13.4043 20.9862 14.0238 21.5173 14.8187C22.0484 15.6135 22.3319 16.548 22.3319 17.5039C22.3319 18.7858 21.8226 20.0152 20.9162 20.9216C20.0098 21.828 18.7804 22.3372 17.4985 22.3372Z"
        fill="black"
      />
      <path
        d="M25.3521 11.4609C26.3531 11.4609 27.1646 10.6495 27.1646 9.64844C27.1646 8.64742 26.3531 7.83594 25.3521 7.83594C24.351 7.83594 23.5396 8.64742 23.5396 9.64844C23.5396 10.6495 24.351 11.4609 25.3521 11.4609Z"
        fill="black"
      />
    </svg>
  );
});
