/* eslint-disable max-lines-per-function */
import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex } from '@chakra-ui/react';
import { LINKS } from '@ours/utils';
import { BrandLogoColored, Close, Instagram } from '@ours/web-icons';
import type { FC, MutableRefObject } from 'react';
import { memo } from 'react';

import { IconButton } from '../Buttons/IconButton';

import { MenuDrawerLink } from './MenuDrawerLink';
import { MENU_DRAWER } from './menuContent';

interface Props {
  activeRoute: string;
  isOpen: boolean;
  navButtonRef: MutableRefObject<null | HTMLButtonElement>;
  onClose: () => void;
}

export const MenuDrawer: FC<Props> = memo(({ activeRoute, isOpen, navButtonRef, onClose }) => {
  return (
    <Drawer
      finalFocusRef={navButtonRef}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      size="md"
    >
      <DrawerOverlay data-testid="MenuDrawerOverlay" />
      <DrawerContent
        border="2px solid black"
        borderBottomLeftRadius={{ base: '500', md: '800' }}
        borderLeftRadius="800"
        borderTopLeftRadius={{ base: '0', md: '800' }}
        data-testid="MenuDrawerContent"
      >
        <Flex
          alignItems="center"
          justifyContent={{ base: 'space-between', md: 'flex-end' }}
          px="300"
          py={{ base: '200', md: '200' }}
          w="full"
        >
          <Box display={{ base: 'block', md: 'none' }}>
            <BrandLogoColored />
          </Box>
          <IconButton Icon={Close} ariaLabel="Close menu" onClick={onClose} type="button" />
        </Flex>

        <DrawerBody overflow="scroll" px="0" py="0">
          <Flex
            flexDir="column"
            gap={{ base: '200', md: '300' }}
            h="full"
            justifyContent="space-between"
          >
            <Flex flexDir="column" gap="100" maxW="100%" ml="-2px" w="max-content">
              {MENU_DRAWER.LINKS.map((link) => {
                return (
                  <MenuDrawerLink
                    isActive={'link' in link ? activeRoute === link.link : false}
                    key={link.text}
                    link={link}
                    onClose={onClose}
                  />
                );
              })}
            </Flex>
            <Box pb={{ base: '100', md: '600' }} pl={{ base: '300', md: '600' }} w="full">
              <Box>{MENU_DRAWER.FEELINGS_TEXT}</Box>
              <Box>{MENU_DRAWER.FEELINGS_TEXT_2}</Box>
              <Box pt="200">
                <Box
                  _hover={{ bg: 'brand.yellow' }}
                  as="a"
                  bg={{ base: 'brand.yellow', md: 'brand.beige' }}
                  border="2px solid black"
                  borderRadius="full"
                  display="inline-block"
                  href={LINKS.INSTAGRAM_LINK}
                  p="150"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Instagram />
                </Box>
              </Box>
            </Box>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
});
